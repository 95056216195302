









































import moment from "moment";
import Vue from "vue";
import { mapActions, mapState } from "vuex";
export default Vue.extend({
  name: "crm-leads-component",
  data() {
    return {
      tempLead: null,
    };
  },
  props: {
    data: Array,
    headers: String,
  },
  computed: {
    ...mapState("crmConfigurationsModule", ["loading"]),
  },
  methods: {
    ...mapActions("crmConfigurationsModule", ["actAddDescription"]),

    prettyDateShowTo(date: any) {
      if (date === null || date === "") return "";

      return moment(date).format("MM/DD/YY, h:mm:ss a");
    },
  },
});
