<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <div>
    <template>
      <div class="row my-1 mx-2">
        <v-btn depressed color="primary" dark @click="OpenDialog('lead', 'none')">+ Add Lead</v-btn>
        <template v-if="isAdmin || isSuper || isSupervisor">
          <v-btn class="mx-2" depressed color="info" dark @click="ImportLead()"><v-icon
              class="mr-1">mdi-cloud-upload</v-icon> Import Lead</v-btn></template>

        <v-spacer></v-spacer>
        <template v-if="isSuper">
          <v-btn class="mx-2" depressed color="info" dark @click="googleSheet()"><v-icon class="mr-1">mdi-cog</v-icon>
            Google Sheet</v-btn>
        </template>
      </div>
    </template>

    <v-card class="rounded-xl overflow-hidden mx-auto">
      <v-toolbar class="primary" tag="div" flat dark tile>
        <v-toolbar-title> Leads Center </v-toolbar-title>
        <v-spacer></v-spacer>
        <div class="d-flex mx-4 mt-4">
          <template v-if="isAdmin || isSuper || isSupervisor">
            <div>
              <v-switch class="mt-2 mx-4" inset color="error" @change="getLeads()" v-model="body.noCoordinator"
                :loading="loading" :label="`No ${getCoordinatorN}`"></v-switch>
            </div>
          </template>
          <div>
            <v-switch class="mt-2 mx-4" inset color="white" @change="getLeads()" v-model="body.cotizedOnly"
              :loading="loading" :label="`Quoted`"></v-switch>
          </div>
          <div>
            <v-autocomplete prepend-inner-icon="mdi-magnify" hide-details clearable outlined @change="getLeads()" dense
              class="mx-2" :items="references" item-text="name" item-value="id" v-model="body.sourceId"
              label="Filter by Source">
              <!-- <template v-slot:prepend-item>
                <v-text-field
                  class="px-2"
                  prepend-inner-icon="mdi-magnify-plus-outline"
                  placeholder="Custom source"
                  clearable
                  @click:clearable="getLeads()"
                  @change="getLeads()"
                  v-model="body.sourceId"
                ></v-text-field>
              </template> -->
              <!--   <template v-slot:selection>
                <span>{{ body.howKnow }}</span>
              </template> -->
            </v-autocomplete>
          </div>

          <template v-if="isAdmin || isSuper || isSupervisor">
            <div>
              <v-autocomplete class="mr-1" :label="`Filter by Employees`" :items="workers"
                v-model="body.coordinatorUuid" item-text="fullname" item-value="uuid" style="max-width: 500px"
                prepend-inner-icon="mdi-account-search" clearable @change="getLeads()" hide-details outlined
                dense></v-autocomplete>
            </div>
          </template>
          <div>
            <v-autocomplete prepend-inner-icon="mdi-magnify" hide-details clearable outlined @change="getLeads()" dense
              class="mx-2" :items="status" item-text="name" item-value="id" v-model="body.status"
              label="Filter by Status">
            </v-autocomplete>
          </div>
          <div class="mx-2">
            <v-select :items="lang" v-model="body.preferLanguage" item-text="label" item-value="value"
              :loading="loading" outlined @change="getLeads" dense label="by Language"></v-select>
          </div>
        </div>
        <v-spacer />
        <template v-if="(isAdmin || isSuper || isSupervisor) && selected.length != 0">
          <asign-to :ocupations="ocupations" :color="'yellow'" @assigned="getLeads" :leadUuids="selected" />
        </template>
        <template v-if="(isAdmin || isSuper || isSupervisor) && selected.length != 0">
          <asign-delete :ocupations="ocupations" :color="'error'" @deleted="getLeads" :leadUuids="selected" />
        </template>
        <template v-if="isSuper && selected.length != 0">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn color="error" v-on="on" @click="toDeleteLEads" icon><v-icon>mdi-delete</v-icon></v-btn>
            </template>
            <span>Delete Selected Leads</span>
          </v-tooltip>
        </template>
      </v-toolbar>

      <v-container>
        <v-row class="my-1 mx-2">
          <v-col cols="12" sm="3">
            <v-text-field v-model.trim="query" class="mr-2" label="Search or filter" hide-details clearable outlined
              dense />
          </v-col>
          <v-col cols="12" sm="3">
            <ma-date-picker :datepickerProps="{
              'no-title': true,
            }" v-model="dateFrom" label="From" past />
          </v-col>
          <v-col cols="12" sm="3">
            <ma-date-picker :datepickerProps="{
              'no-title': true,
            }" v-model="dateTo" label="To" past />
          </v-col>
          <v-col cols="12" sm="3" class="d-flex justify-end">
            <v-btn fab small dark class="mr-1" color="indigo" @click="refresh('dates')">
              <v-icon dark> mdi-refresh </v-icon>
            </v-btn>
            <template>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" fab small dark color="info"
                    @click="gotoConfigure()"><v-icon>mdi-account-cog</v-icon></v-btn>
                </template>
                <span>Columns Configure</span>
              </v-tooltip>
            </template>
          </v-col>

          <v-dialog v-model="configureDialog" persistent :overlay="false" max-width="800px"
            transition="dialog-transition">
            <v-card>
              <v-card-title class="headline primary white--text">
                Columns
              </v-card-title>
              <v-card-text class="mt-3">
                <div class="text-center">
                  <h3>Select the columns you want to show:</h3>
                </div>
                <div class="d-flex justify-space-between">
                  <div>
                    <v-checkbox label="Select All" v-model="showAll" color="success" :value="true"></v-checkbox>
                  </div>
                </div>
                <div class="d-flex flex-wrap mb-4">
                  <v-chip class="ma-2 chipsCols" outlined color="primary" label v-for="(header, i) in headersToChange"
                    :key="i">
                    <div class="d-flex justify-space-between contentCols">
                      <span>{{ header.text }}</span>

                      <template v-if="header.show">
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <v-icon color="success" v-on="on" class="ml-2" right @click="updateHeader(false, i)">
                              mdi-eye
                            </v-icon>
                          </template>
                          <span>Click to Hide</span>
                        </v-tooltip>
                      </template>
                      <template v-else>
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <v-icon color="error" v-on="on" class="ml-2" right @click="updateHeader(true, i)">
                              mdi-eye-off
                            </v-icon>
                          </template>
                          <span>Click to Show</span>
                        </v-tooltip></template>
                    </div>
                  </v-chip>
                </div>
              </v-card-text>
              <v-card-actions class="d-flex justify-end">
                <v-btn color="info" text @click="defaultHeaders">default</v-btn>
                <v-btn text color="success" @click="setHeaders()">confirm</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
        <template>
          <v-data-table v-model="selected" :loading="loading || loadingAcciones" :headers="headers"
            :options.sync="options" :items="leads" :footer-props="footerProps"
            :show-select="isAdmin || isSuper || isSupervisor" :server-items-length="totalleads" item-key="uuid"
            class="elevation-1">
            <template v-slot:[`item.name`]="{ item }">
              <div class="cursor-pointer" @click="gotoLead(item)">
                {{ item.name + " " + item.last_name }}
              </div>
            </template>
            <template v-slot:[`item.coordinator`]="{ item }">
              <div class="cursor-pointer" @click="gotoLead(item)">
                {{ getCoordinator(item) }}
              </div>
            </template>
            <template v-slot:[`item.quote`]="{ item }">
              <template v-if="item.quotedDate">
                <v-icon color="success">mdi-check</v-icon>
              </template>
              <template v-else>
                <v-icon color="error">mdi-close</v-icon>
              </template>
            </template>
            <template v-slot:[`item.quotedDate`]="{ item }">
              <div class="cursor-pointer" @click="gotoLead(item)">
                <template v-if="item.quotedDate">
                  {{ item.quotedDate ? prettyDate(item.quotedDate) : "-" }}
                </template>
                <template v-else>
                  <v-icon color="error">mdi-close</v-icon>
                </template>
              </div>
            </template>
            <template v-slot:[`item.rd`]="{ item }">
              <div class="cursor-pointer" @click="gotoLead(item)">
                {{ getRDate(item) ? prettyDate(getRDate(item)) : "-" }}
              </div>
            </template>
            <template v-slot:[`header.members`]="{ header }">
              <template v-if="totalMembers && totalMembers.count_members">
                {{ `${header.text}(${totalMembers.count_members})` }}
              </template>
              <template v-else>
                {{ `${header.text}` }}{{ loadingSummary ? "(...)" : "" }}
              </template>
            </template>
            <template v-slot:[`header.smsCount`]="{ header }">
              <template v-if="totalMembers && totalMembers.count_sms">
                {{ `${header.text}(${totalMembers.count_sms})` }}
              </template>
              <template v-else>
                {{ `${header.text}` }}{{ loadingSummary ? "(...)" : "" }}
              </template>
            </template>
            <template v-slot:[`header.name`]="{ header }">
              <template v-if="totalMembers && totalMembers.count_leads">
                {{ `${header.text}(${totalMembers.count_leads})` }}
              </template>
              <template v-else>
                {{ `${header.text}` }}{{ loadingSummary ? "(...)" : "" }}
              </template>
            </template>
            <template v-slot:[`header.callCount`]="{ header }">
              <template v-if="totalMembers && totalMembers.count_calls">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <span v-on="on">{{
                      `${header.text}(${totalMembers.count_calls})`
                    }}</span>
                  </template>
                  <div class="d-flex flex-column">
                    <span>Incoming Calls: {{ totalMembers.inbound_calls }}</span>
                    <span>Outgoing Calls: {{ totalMembers.outbound_calls }}</span>
                  </div>
                </v-tooltip>
              </template>
              <template v-else>
                {{ `${header.text}` }}{{ loadingSummary ? "(...)" : "" }}
              </template>
            </template>
            <template v-slot:[`header.status`]="{ header }">
              <div class="d-flex">
                <span class="mt-1">
                  {{ `${header.text}` }}
                </span>
                <template v-if="showStatus.data.length != 0">
                  <v-icon color="success" @click="showStatusSummary">mdi-eye</v-icon>
                </template>
              </div>
            </template>
            <template v-slot:[`header.sourceNom`]="{ header }">
              <div class="d-flex">
                <span class="mt-1">
                  {{ `${header.text}` }}
                </span>
                <template v-if="showSource.data.length != 0">
                  <v-icon color="info" @click="showSourceSummary">mdi-eye</v-icon>
                </template>
              </div>
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <v-menu offset-x>
                <template v-slot:activator="{ on }">
                  <span v-on="on" v-bind:style="{ color: colorShow(item) }" class="font-weight-bold cursor-pointer">{{
                    statusShow(item) }}</span>
                </template>
                <v-list>
                  <v-list-item class="cursor-pointer" v-for="(st, index) in status" :key="index" link
                    :disabled="statusShowReal(item) == st.name" @click="changeStatus(item, st)">
                    <v-list-item-title :class="statusShowReal(item) == st.name ? 'red--text' : ''
                      ">{{ st.name }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
            <template v-slot:[`item.createAt`]="{ item }">
              <div class="cursor-pointer" @click="gotoLead(item)">
                {{ formatDate(item.createAt) }}
              </div>
            </template>
            <template v-slot:[`item.tagNom`]="{ item }">
              <div class="cursor-pointer" @click="gotoLead(item)">
                <span v-bind:style="{ color: colorShowT(item) }" class="font-weight-bold">{{ item.tagNom ?
                  item.tagNom.name : "-" }}</span>
              </div>
            </template>

            <template v-slot:[`item.sourceNom`]="{ item }">
              <div class="cursor-pointer" @click="gotoLead(item)">
                <span v-bind:style="{ color: colorShowS(item) }" class="font-weight-bold">{{ item.sourceNom ?
                  item.sourceNom.name : "-" }}</span>
              </div>
            </template>
            <template v-slot:[`item.phone`]="{ item }">
              <template v-if="item.phone">
                <div class="d-flex">
                  <div class="cursor-pointer" @click="makeCallV(item.phone)">
                    {{ item.phone }}
                  </div>
                  <div class="mx-1">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-icon @click="copyPhone(item.phone)" v-on="on" small color="info">mdi-content-copy</v-icon>
                      </template>
                      <span>Copy Phone</span>
                    </v-tooltip>
                  </div>
                </div>
              </template>
              <template v-else> - </template>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <div class="d-flex jusitify-end">
                <template>
                  <v-tooltip color="info" top>
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" color="primary" :disabled="!item.quotedDate"
                        icon><v-icon>mdi-currency-usd</v-icon></v-btn>
                    </template>

                    <v-row class="toolt" no-gutters>
                      <v-col cols="12" sm="4" class="d-flex flex-column">
                        <label class="font-weight-bold" for="">Quoted Date</label>
                        <span>{{ formatDate(item.quotedDate) }}</span>
                      </v-col>
                      <v-col cols="12" sm="4" class="d-flex flex-column">
                        <label class="font-weight-bold" for="">State</label>
                        <span>{{ item.state }}</span>
                      </v-col>
                      <v-col cols="12" sm="4" class="d-flex flex-column">
                        <label class="font-weight-bold" for="">Company</label>
                        <span>{{
                          item.company ? item.company.name : "-"
                        }}</span>
                      </v-col>
                    </v-row>
                    <v-row class="mt-4 toolt" no-gutters>
                      <v-col cols="12" sm="4" class="d-flex flex-column">
                        <label class="font-weight-bold" for="">Members</label>
                        <span>{{ item.members }}</span>
                      </v-col>
                      <v-col cols="12" sm="4" class="d-flex flex-column">
                        <label class="font-weight-bold" for="">Metal Plan</label>
                        <span class="text-body-1 font-weight-bold" v-bind:style="{
                          color: colorPolicy(item.policyPlan),
                        }">{{ item.policyPlan }}</span>
                      </v-col>
                      <v-col cols="12" sm="4" class="d-flex flex-column">
                        <label class="font-weight-bold" for="">Premium</label>
                        <span>{{ item.premium | currency }}</span>
                      </v-col>
                    </v-row>
                  </v-tooltip>
                </template>

                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <router-link class="leaddetails" :to="'/leads_details/' + item.uuid"><v-icon v-on="on"
                        color="success">mdi-eye</v-icon></router-link>
                  </template>

                  <span>Lead Details</span>
                </v-tooltip>

                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" small icon color="primary"
                      @click="goEdit(item)"><v-icon>mdi-pencil</v-icon></v-btn>
                  </template>

                  <span>Edit Lead</span>
                </v-tooltip>
                <template v-if="isSuper || isAdmin">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" small icon @click="toDelete(item)" color="error"><v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </template>

                    <span>Delete Lead</span>
                  </v-tooltip>
                </template>
              </div>
            </template>
          </v-data-table>
        </template>
      </v-container>
      <!-- Quote Details -->
      <v-dialog v-model="quoteDialog" persistent :overlay="false" max-width="600px" transition="dialog-transition">
        <v-card>
          <v-toolbar class="primary" tag="div" flat dark tile>
            <v-toolbar-title> Quote Details </v-toolbar-title></v-toolbar>
          <v-card-text class="my-2">
            <v-container>
              <template v-if="quoteDetails">
                <v-row no-gutters>
                  <v-col cols="12" sm="4" class="d-flex flex-column">
                    <label class="font-weight-bold" for="">Quoted Date</label>
                    <span>{{ formatDate(quoteDetails.quotedDate) }}</span>
                  </v-col>
                  <v-col cols="12" sm="4" class="d-flex flex-column">
                    <label class="font-weight-bold" for="">State</label>
                    <span>{{ quoteDetails.state }}</span>
                  </v-col>
                  <v-col cols="12" sm="4" class="d-flex flex-column">
                    <label class="font-weight-bold" for="">Company</label>
                    <span>{{
                      quoteDetails.company ? quoteDetails.company.name : "-"
                    }}</span>
                  </v-col>
                </v-row>
                <v-row class="mt-4" no-gutters>
                  <v-col cols="12" sm="4" class="d-flex flex-column">
                    <label class="font-weight-bold" for="">Members</label>
                    <span>{{ quoteDetails.members }}</span>
                  </v-col>
                  <v-col cols="12" sm="4" class="d-flex flex-column">
                    <label class="font-weight-bold" for="">Metal Plan</label>
                    <span class="text-body-1 font-weight-bold" v-bind:style="{
                      color: colorPolicy(quoteDetails.policyPlan),
                    }">{{ quoteDetails.policyPlan }}</span>
                  </v-col>
                  <v-col cols="12" sm="4" class="d-flex flex-column">
                    <label class="font-weight-bold" for="">Premium</label>
                    <span>{{ quoteDetails.premium | currency }}</span>
                  </v-col>
                </v-row>
              </template>
            </v-container>
          </v-card-text>
          <v-card-actions class="d-flex justify-end">
            <v-btn color="info" text @click="closeDialog">close</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!--  Delete Lead -->
      <v-dialog v-model="deleteL.deleteDialog" persistent :overlay="false" max-width="300px"
        transition="dialog-transition">
        <v-card>
          <v-toolbar dark color="error">
            Delete Lead: {{ deleteL.deleteItem.name }}
          </v-toolbar>
          <v-card-text class="mt-3">
            <div class="pa-2 text-center body-1">Are you sure ?</div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="cancelDelete">Cancel</v-btn>
            <v-btn text :loading="loadingAcciones" @click="confirmDelte" color="error">Delete</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="toDeleteLeads.dialog" persistent :overlay="false" max-width="300px"
        transition="dialog-transition">
        <v-card>
          <v-toolbar dark color="error"> Delete Leads </v-toolbar>
          <v-card-text class="mt-3">
            <div class="pa-2 text-center body-1">Are you sure ?</div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="cancelDeleteLEads">Cancel</v-btn>
            <v-btn text :loading="toDeleteLeads.loading" @click="confirmDeleteLEads" color="error">Delete</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="showStatus.dialog" :overlay="false" max-width="500px" transition="dialog-transition">
        <v-card>
          <template>
            <v-data-table :headers="showStatus.headers" :items="showStatus.data" :items-per-page="10"
              class="elevation-1">
              <template v-slot:[`item.status`]="{ item }">
                <div class="text-body-1 cursor-pointer" v-bind:style="{ color: colorShow(item) }">
                  {{ statusShow(item) }}
                </div>
              </template>
            </v-data-table>
          </template>
        </v-card>
      </v-dialog>
      <v-dialog v-model="showSource.dialog" :overlay="false" max-width="500px" transition="dialog-transition">
        <v-card>
          <template>
            <v-data-table :headers="showSource.headers" :items="showSource.data" :items-per-page="10"
              class="elevation-1">
              <template v-slot:[`item.source`]="{ item }">
                <div class="text-body-1 cursor-pointer font-weight-bold" v-bind:style="{ color: colorSourceEye(item) }">
                  {{ item.source }}
                </div>
              </template>
            </v-data-table>
          </template>
        </v-card>
      </v-dialog>
    </v-card>
  </div>
</template>

<script>
import Vue from "vue";
/* import { Container, Draggable } from "vue-smooth-dnd"; */
/* import LeadDashboardGroup from "./LeadDasboardGroup.vue"; */

import { mapState, mapActions, mapMutations, mapGetters } from "vuex";
import SendEmail from "@/components/emails/SendEmail.vue";
import TableLeads from "@/views/Configurations/components/TableLeads.vue";

import MaDatePicker from "@/components/MaDatePicker/MaDatePicker.vue";
import TableCalls from "@/views/Configurations/components/TableCalls.vue";
import BtnMakeCall from "@/views/CallCenter/buttons/BtnMakeCall.vue";

import AsignTo from "@/views/Configurations/components/AsignTo.vue";
import AsignDelete from "@/views/Configurations/components/AsignDelete.vue";
import DrawerSms from "@/views/SMS/components/DrawerSms.vue";
import { Lead, LeadStatus } from "@/models/Lead";

import { desireSurgery } from "@/utils/consts";
import { getAPI } from "@/api/axios-base";
import {
  notifyError,
  notifyInfo,
  notifySuccess,
} from "@/components/Notification";

import moment from "moment";
import AllImages from "@/components/Images/AllImages.vue";
import { funtionsPhone } from "@/utils/mixins";
import { cleanPhoneNumber, isPhoneNumber } from "@/utils/formatPhone";

export default Vue.extend({
  mixins: [funtionsPhone],
  components: {
    /*  LeadDashboardGroup, */
    /* Draggable, */
    /*  Container, */
    SendEmail,
    MaDatePicker,
    DrawerSms,
    TableLeads,
    TableCalls,
    BtnMakeCall,
    AsignTo,
    AllImages,
    AsignDelete,
  },
  data: () => ({
    ocupations: [],
    quoteDialog: false,
    quoteDetails: null,
    loadingAcciones: false,
    loadingSummary: false,
    totalMembers: null,

    showStatus: {
      dialog: false,
      data: [],
      headers: [
        { text: "Status", value: "status", sortable: false },
        { text: "Leads", value: "count_leads", sortable: false },
      ],
    },
    showSource: {
      dialog: false,
      data: [],
      headers: [
        { text: "Source", value: "source", sortable: false },
        { text: "Leads", value: "count_leads", sortable: false },
      ],
    },
    preferLanguage: null,
    lang: [
    { label: "All", value: null },
    { label: "English", value: "ENGLISH" },
    { label: "Spanish", value: "SPANISH" },
    ],
    deleteL: {
      deleteItem: {},
      deleteDialog: false,
    },
    toDeleteLeads: {
      dialog: false,
      items: [],
      loading: false,
    },
    desireSurgery,
    showcalendar: false,
    changed: false,
    formValid: false,

    evaluation: false,
    tabSelect: null,
    title: "",
    types: [],
    footerProps: { "items-per-page-options": [15, 50, 100, 500, 1000] },
    details: {},

    options: {},
    dialogNote: false,
    dialogLead: false,
    selected: [],
    detailsnote: "",
    loadingProcedures: false,

    dateTo: "",
    dateFrom: "",
    uuidLead: "",
    query: null,
    body: {
      query: null,
      dates: null,
      status: null,
      noCoordinator: false,
      cotizedOnly: false,
      preferLanguage:null,
      coordinatorUuid: null,
      sourceId: null,
      interval: {
        offset: 10,
        limit: 0,
      },
    },
    upperDropPlaceholderOptions: {
      className: "cards-drop-preview",
      animationDuration: "150",
      showOnTop: true,
    },
    dropPlaceholderOptions: {
      className: "drop-preview",
      animationDuration: "150",
      showOnTop: true,
    },
    expanded: [],
    status: [],
    headersToChange: [],
    configureDialog: false,
    showAll: false,
  }),
  computed: {
    ...mapGetters([
      "getCoordinatorN",
      "getpatientN",
      "isSupervisor",
      "isAdmin",
      "isSuper",
      "getleadHeaders",
    ]),
    ...mapGetters("crmConfigurationsModule", [
      "headersLeadShow",
      "headersLead",
    ]),
    ...mapState("crmConfigurationsModule", ["leads", "loading", "totalleads"]),
    ...mapState("crmSettingsModule", ["loadingRefer", "references"]),
    ...mapState("crmEmployeeModule", ["doctors", "workers"]),

    ...mapState("crmCallCenterModule", ["callsLead"]),
    ...mapState("crmMedicFormModule", ["patients"]),
    /*  dateRangeText() {
      let temp = (this).body.dates;
      if ((this).body.dates.length > 1) {
        const val1 = (this).body.dates[0].split("-").splice(2, 1);
        const val2 = (this).body.dates[1].split("-").splice(2, 1);
        if (Number(val1) > Number(val2)) {
          temp = [];
          temp.push((this).body.dates[1]);
          temp.push((this).body.dates[0]);
        }
      }
      return temp.join(" ~ ");
    }, */

    desireSurgerySelect() {
      if (
        this.expanded.length == 0 ||
        this.expanded[0].medicalHistory == null
      ) {
        return null;
      }
      if (this.expanded[0].medicalHistory.desiredSurgeryDate) {
        const t = this.expanded[0].medicalHistory.desiredSurgeryDate;
        const desire = this.desireSurgery.filter((d) => d.value == t);
        return desire[0];
      }
      return null;
    },

    headers() {
      return this.headersLeadShow;
    },
    bmi() {
      let inches = 0;
      let feets = 0;
      if (this.expanded.length == 0) {
        return 0;
      }
      if (this.expanded[0].weight == 0 && this.expanded[0].height == 0)
        return 0;
      const he = this.expanded[0].height.toString();
      if (he.includes(".")) {
        inches = Number(he.split(".")[1]);
        feets = he.split(".")[0] ? Number(he.split(".")[0]) : 0;
      } else if (he.includes(",")) {
        inches = Number(he.split(",")[1]);
        feets = he.split(".")[0] ? Number(he.split(",")[0]) : 0;
      } else {
        feets = he;
      }

      const inchtometer = inches * 0.0254;
      const feettometer = feets * 0.3048;
      const bmi =
        (Number(this.expanded[0].weight) * 0.453592) /
        Math.pow(feettometer + inchtometer, 2);

      return Number(bmi).toFixed(2);
    },

    typebmi() {
      const bmitemp = Number(this.bmi);

      if (bmitemp == 0) {
        return "No BMI";
      }
      if (bmitemp <= 18.5) {
        return "Underweight";
      }
      if (bmitemp > 18.5 && bmitemp <= 24.9) {
        return "Normal weight";
      }
      if (bmitemp >= 25 && bmitemp <= 29.9) {
        return "Overweight";
      }

      return "Obesity";
    },
    colorWeight() {
      if (this.typebmi == "No BMI") {
        return "grey";
      }
      if (this.typebmi == "Underweight") {
        return "amber";
      }
      if (this.typebmi == "Normal weight") {
        return "green";
      }
      if (this.typebmi == "Overweight") {
        return "orange";
      }
      if (this.typebmi == "Obesity") {
        return "red darken-2";
      }
    },
  },
  async mounted() {
    await this.loadLeadStatus();
    this.listOcupations();
    this.actListReferences();
    this.actGetPatientDoctors();
    if (this.isAdmin || this.isSuper) {
      await this.actGetEmployees();
    }

    localStorage.removeItem("from");
  },
  watch: {
    expanded(val) {
      if (val != []) {
        this.expandedLead();
      }
    },
    showAll(val) {
      if (val == true) {
        this.setAll(true);
      } else {
        this.setAll(false);
      }
    },
    options(val) {
      if (val != {}) {
        this.getLeads();
      }
    },
    dateFrom(val) {
      if (val !== null && this.dateTo !== null && this.dateTo !== "") {
        this.getLeads();
      }
    },
    dateTo(val) {
      if (val !== null && this.dateFrom !== null && this.dateFrom !== "") {
        this.getLeads();
      }
    },
    query(val) {
      if (val == null) {
        this.body.query = val;
        this.getLeads();
      } else if (val.length >= 3 || val.length == 0) {
        if (isPhoneNumber(val)) {
          this.query = cleanPhoneNumber(val);
          if (this.query[0] == "1") {
            this.query = this.query.slice(1);
          }
          this.body.query = this.query;
        } else {
          this.body.query = val;
        }
        this.getLeads();
      }
    },
  },
  destroyed() {
    localStorage.removeItem("leadcenter");
    this.mutSetLeads([]);
    this.mutSetTotalLeads(0);
  },
  methods: {
    ...mapActions("crmConfigurationsModule", [
      "actGetLeads",
      "actConverToPatient",
      "actAddNote",
      "actAddLead",
    ]),

    ...mapActions("crmEmployeeModule", [
      "actGetEmployees",
      "actGetPatientDoctors",
    ]),
    ...mapActions("crmCallCenterModule", ["actFilterCalls"]),
    ...mapMutations("crmConfigurationsModule", [
      "mutleadSelect",
      "mutSetLeads",
      "mutSetTotalLeads",
      "mutSetHeaders",
    ]),
    ...mapActions("crmSettingsModule", ["actListReferences"]),
    gotoLead(item) {
      this.$router.push(`/leads_details/${item.uuid}`);
    },

    setAll(val) {
      this.headersToChange.forEach((element) => {
        element.show = val;
      });
    },

    setHeaders() {
      this.mutSetHeaders(this.headersToChange);
      this.configureDialog = false;
    },
    gotoConfigure() {
      this.headersToChange = this.headersLead.map((ele) => {
        return ele;
      });
      this.configureDialog = true;
    },

    updateHeader(value, index) {
      this.headersToChange[index].show = value;
    },
    defaultHeaders() {
      this.headersToChange = [
        {
          text: "Creation Date",
          align: "start",
          sortable: false,
          value: "createAt",
          show: true,
        },
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "name",
          show: true,
        },
       // { text: "Email", value: "email", sortable: false, show: false }, 
        { text: "Phone", value: "phone", sortable: false, show: true },
        { text: "Source", value: "sourceNom", sortable: false, show: true },
        { text: "Language", value: "preferLanguage", sortable: false, show: true },
        { text: "Tag", value: "tagNom", sortable: false, show: false },
        { text: "Agent", value: "coordinator", sortable: false, show: true },
        {
          text: "R-Date",
          align: "start",
          width: 110,
          value: "rd",
          sortable: false,
          show: true,
        },
        {
          text: "Members",
          value: "members",
          align: "center",
          sortable: false,
          show: false,
        },
        {
          text: "Status",
          value: "status",
          align: "center",
          sortable: false,
          show: true,
        },
        {
          text: "Quote Date",
          value: "quotedDate",
          align: "center",
          sortable: false,
          show: false,
        },
        {
          text: "Sms",
          value: "smsCount",
          sortable: false,
          align: "center",
          show: true,
        },
        {
          text: "Calls",
          value: "callCount",
          sortable: false,
          align: "center",
          show: true,
        },
        {
          text: "",
          value: "actions",
          align: "end",
          sortable: false,
          show: true,
        },
      ];
      this.mutSetHeaders(this.headersToChange);
      this.configureDialog = false;
    },

    showQuoted(item) {
      this.quoteDialog = true;
      this.quoteDetails = {
        quotedDate: item.quotedDate,
        company: item.company,
        members: item.members,
        premium: item.premium,
        state: item.state,
        policyPlan: item.policyPlan,
      };
    },
    closeDialog() {
      this.quoteDialog = false;
      this.quoteDetails = null;
    },

    changeStatus(item, status) {
      this.loadingAcciones = true;
      let body = {
        leadUuid: item.uuid,
        statusNom: status.id,
      };
      getAPI
        .put("/social/updateCounts", body)
        .then(() => {
          notifyInfo("Lead Status has been changed");
          this.loadingAcciones = false;
          this.getLeads();
        })
        .catch((error) => {
          this.loadingAcciones = false;
          let mess = error.response.data.message.toString();
          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },
    loadLeadStatus() {
      getAPI(`/lead-status-nom/list`)
        .then((res) => {
          this.status = res.data.filter((f) => f.status != "NO_ACTIVE");
        })
        .catch((error) => {
          let mess = error.response.data.message.toString();
          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },
    async listOcupations() {
      this.ocupations = (await getAPI(`/ocupation-nom/list`)).data;
    },
    statusShow(item) {
      const est = item.statusNom
        ? item.statusNom.name
        : item.status
        ? item.status
        : "No Status";
      if (est == null || est == undefined) {
        return "-";
      }
      switch (est) {
        case LeadStatus.NotInterested:
          return "Not Interested";
        case LeadStatus.WrongNumber:
          return "Wrong Number";
        default:
          return est;
      }
    },
    statusShowReal(item) {
      const est = item.statusNom ? item.statusNom.name : "No Status";
      if (est == null || est == undefined) {
        return "-";
      }
      return est;
    },
    colorShow(item) {
      return item.statusNom ? item.statusNom.color : "#000";
    },
    colorSourceEye(data) {
      if (this.references.length != 0) {
        const x = this.references.find((r) => r.name == data.source);
        return x ? x.color : "#000";
      }
      return "#000";
    },
    colorShowS(item) {
      return item.sourceNom ? item.sourceNom.color : "#000";
    },
    colorShowT(item) {
      return item.tagNom ? item.tagNom.color : "#000";
    },

    convertToPatient(lead) {
      this.mutleadSelect(lead);
      this.$router.push("/clients/create");
    },
    goEdit(item) {
      localStorage.setItem("from", "center");
      this.$router.push("/leads_center/edit/" + item.uuid);
    },
    async expandedLead() {
      if (this.expanded.length != 0) {
        this.uuidLead = this.expanded[0].uuid;
        this.details = this.expanded[0];
        const phone = this.expanded[0].phone;
        await this.actFilterCalls({
          whateverNumber: phone,
          limit: 500,
          offset: 0,
        });
      } else {
        this.uuidLead = "";
        this.details = {};
      }
    },

    toIsoString(values) {
      let temp = [];
      if (values.length == 1 || values[0] == values[1]) {
        const date1 = new Date(values[0]);
        const date2 = new Date(values[0]);
        date2.setUTCHours(23, 59);
        temp.push(date1.toISOString());
        temp.push(date2.toISOString());
      } else {
        values.forEach((val) => {
          const date = new Date(val);
          temp.push(date.toISOString());
        });
      }

      return temp;
    },

    toDelete(item) {
      this.deleteL.deleteDialog = true;
      this.deleteL.deleteItem = item;
    },
    cancelDelete() {
      this.deleteL = { deleteItem: {}, deleteDialog: false };
    },
    confirmDelte() {
      this.loadingAcciones = true;
      getAPI
        .delete("/social/delete/" + this.deleteL.deleteItem.uuid)
        .then(() => {
          notifyInfo("Lead has been deleted");
          this.loadingAcciones = false;
          this.cancelDelete();
          this.getLeads();
        })
        .catch((error) => {
          this.loadingAcciones = false;
          let mess = error.response.data.message.toString();

          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },

    getLeads() {
      this.selected = [];
      let reqbody = {};
      if (
        localStorage.getItem("leadcenter") &&
        localStorage.getItem("filtersleads")
      ) {
        const localdata = JSON.parse(localStorage.getItem("filtersleads"));
        this.body = localdata.body;
        this.dateTo = localdata.dateto;
        this.dateFrom = localdata.datefrom;        
        this.options.page = localdata.page;
        this.options.itemsPerPage = localdata.itemsPerPage;
      }

      reqbody = {
        query: this.body.query,
        status: this.body.status,
        noCoordinator: this.body.noCoordinator,
        cotizedOnly: this.body.cotizedOnly,
        preferLanguage:this.body.preferLanguage,
        coordinatorUuid: this.body.coordinatorUuid,
        interval: this.body.interval,
        sourceId: this.body.sourceId,
      };

      if (this.body.dates != null) {
        reqbody = { ...reqbody, dateInterval: this.body.dates };
      }
      if (
        this.dateTo != "" &&
        this.dateTo != null &&
        this.dateTo != undefined &&
        this.dateFrom != "" &&
        this.dateFrom != null &&
        this.dateFrom != undefined
      ) {
        reqbody = {
          ...reqbody,
          dateInterval: {
            date1: moment(this.dateFrom).startOf("day").utc().toISOString(),
            date2: moment(this.dateTo).endOf("day").utc().toISOString(),
          },
        };
      }
      const { page, itemsPerPage } = this.options;
      const itmper = itemsPerPage != undefined ? itemsPerPage : 15;
      const p = page != undefined ? page : 1;
      const reqrange = {
        limit: itmper,
        offset: (p - 1) * itmper,
      };
      reqbody.interval = reqrange;
      this.body = {
        query: reqbody.query,
        status: reqbody.status,
        noCoordinator: reqbody.noCoordinator,
        cotizedOnly: reqbody.cotizedOnly,
        interval: reqbody.interval,
        preferLanguage:reqbody.preferLanguage,
        dates: reqbody.dateInterval ? reqbody.dateInterval : null,
        coordinatorUuid: reqbody.coordinatorUuid,
        sourceId: reqbody.sourceId,
      };
      reqbody = this.cleanNull(reqbody);

      localStorage.setItem(
        "filtersleads",
        JSON.stringify({
          body: this.body,
          dateto: this.dateTo,
          datefrom: this.dateFrom,
          page,
          itemsPerPage,
        })
      );
      localStorage.removeItem("leadcenter");
      this.actGetLeads(reqbody);
      this.getLeadSummary(reqbody);
    },
    getLeadSummary(filters) {
      delete filters.interval;
      this.loadingSummary = true;
      getAPI
        .post("/social/summaryFilterLeads", filters)
        .then((res) => {
          this.loadingSummary = false;
          const response = res.data;
          const t = response.find((r) => r.status == "TOTAL");
          const data = response.filter(
            (r) => r.status != "TOTAL" && r.source == null
          );
          const dataS = response.filter(
            (r) => r.status != "TOTAL" && r.source != null
          );
          this.totalMembers = t;
          this.showStatus.data = data;
          this.showSource.data = dataS;
        })
        .catch((error) => {
          this.loadingSummary = false;
          let mess = error.response.data.message.toString();

          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },
    showStatusSummary() {
      this.showStatus.dialog = true;
    },
    showSourceSummary() {
      this.showSource.dialog = true;
    },
    toDeleteLEads() {
      this.toDeleteLeads.dialog = true;
      this.toDeleteLeads.items = this.selected.map((s) => {
        return s.uuid;
      });
      this.loading = false;
    },
    cancelDeleteLEads() {
      this.toDeleteLeads = { dialog: false, loading: false, items: [] };
    },
    confirmDeleteLEads() {
      this.toDeleteLeads.loading = true;

      const body = { leadUuids: this.toDeleteLeads.items };
      getAPI
        .delete("/social/deleteMasiveLead", { data: body })
        .then((res) => {
          this.cancelDeleteLEads();
          this.getLeads();
        })
        .catch((error) => {
          this.toDeleteLeads.loading = false;
          let mess = error.response.data.message.toString();

          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },

    formatDate(date) {
      const d = moment(date).format("MM/DD/YYYY");
      return d;
    },
    getHeight(height) {
      if (height == undefined || height == null || height == "") {
        return 0;
      }
      const ht = height.toString();
      if (ht.includes(".")) {
        return ht.split(".")[0] + " ft, " + ht.split(".")[1] + " in";
      } else if (ht.includes(",")) {
        return ht.split(",")[0] + " ft, " + ht.split(",")[1] + " in";
      } else {
        return ht + " ft";
      }
    },

    getDoctorName(item) {
      if (item.medicalHistory.doctorChoice != null) {
        return item.medicalHistory.doctorChoice.fullname;
      }
      return "None";
    },

    /*  formatTime(date) {
      if (!date) return null;
      const [fecha, hora] = date.split("T");
      let [hours, minutes, seconds] = hora.split(":");
      seconds = seconds.substr(-20, 2);
      return `${hours}:${minutes}:${seconds}`;
    }, */

    showDetails(patient) {
      this.$router.push(`/clients/details/${patient.uuid}`);
    },

    OpenDialog(type, uuid) {
      switch (type) {
        case "note":
          this.dialogNote = true;
          break;
        default:
          this.$router.push(`/leads_center/new_lead`);

          break;
      }
      this.uuidLead = uuid ? uuid : "none";
    },
    ImportLead() {
      this.$router.push(`/leads_center/import_lead`);
    },
    googleSheet() {
      this.$router.push(`/leads_center/google_sheet_list`);
    },

    updateFiles() {
      setTimeout(() => {
        this.$refs.images.getImages();
      }, 500);
    },

    async addNote() {
      await this.actAddNote({
        details: this.detailsnote,
        leadUuid: this.uuidLead,
        type: this.evaluation ? "EVALUATION" : "ANY",
      });
      this.cancelDialog();
      this.getLeads();
    },

    cleanNull(obj) {
      for (var propName in obj) {
        if (
          obj[propName] === null ||
          obj[propName] === undefined ||
          obj[propName] === ""
        ) {
          delete obj[propName];
        }
      }
      return obj;
    },

    getCoordinator(item) {
      if (
        item.relations == null ||
        item.relations == undefined ||
        item.relations.length == 0
      ) {
        return "-";
      } else {
        const x = item.relations[0];
        if (x) {
          return x.employee.fullname;
        }
        return "-";
      }
    },
    getRDate(item) {
      if (
        item.relations == null ||
        item.relations == undefined ||
        item.relations.length == 0
      ) {
        return null;
      } else {
        const x = item.relations[0];
        if (x) {
          return x.createdAt;
        }
        return null;
      }
    },

    prettyDate(date) {
      if (date === null || date === "") return "-";

      return moment(date).format("MM/DD/YYYY");
    },

    cancelDialog() {
      this.dialogNote = false;
      this.loadingAcciones = false;
      this.dialogLead = false;
      this.evaluation = false;
      this.descriptioncall = "";
      this.detailsnote = "";
    },

    refresh(type) {
      switch (type) {
        case "dates":
          this.dateTo = "";
          this.dateFrom = "";
          this.body.dates = null;
          break;
        default:
          this.body.query = null;
          break;
      }
      this.getLeads();
    },

    colorPolicy(value) {
      switch (value) {
        case "GOLD":
          return "#EDC43D";
        case "SILVER":
          return "#C0C0C0";
        case "BRONZE":
          return "#CD7F32";

        default:
          return "#000";
      }
    },
  },
});
</script>
<style lang="scss" scoped>
.cardexpand {
  width: 100vh !important;
}

.texts {
  margin-top: -26px !important;
  margin-bottom: 10px !important;
}

.texts1 {
  margin-top: -40px !important;
  margin-bottom: 10px !important;
}

.texts2 {
  margin-top: -40px !important;
  margin-bottom: 8px !important;
}

.actions {
  margin-top: -50px !important;
}

.texts3 {
  margin-top: -40px !important;
  margin-bottom: 5px !important;
}

.swipri {
  margin-top: -1px !important;
}

.borderdivider {
  border: 1px solid;
}

.toolt {
  width: 320px !important;
}

.test {
  border: 1px solid red;
}

.leaddetails {
  text-decoration: none;
  margin: 2px 0px;
}

.contentCols {
  width: 160px !important;
}
</style>
